// site colors
$mainColor0: #000000;
$mainColor1: #03FFBA; /* logo color */
$mainColor2: #2A2A2A;
$mainColor3: #161616;
$mainColor4: #90e8d1; /* Ex-color - lighter logo color for hover states */
$mainColor5: #a0a0a0;
$mainColor6: #c0c0c0;
$mainColor7: #505050;
$mainColor8: #353535;
$mainColor9: #ffffff;
$mainColor10: #646464;
$mainColor11: #5b6b6e; /* logo color - text (black BG) */
$mainColor12: #404040;
$mainColor14: #F2EB46; /* Brand color */
$mainColor15: #231F20; /* logo BG color */
$mainColor16: #feffa8; /* Ex-color - lighter Brand color for hover states */
$mainColor17: #f1a0a0;
$mainColor18: #e00000;
$mainColor19: darken($mainColor11, 5%); /* logo color - text (black BG) - darker */
$mainColor20: darken($mainColor1, 15%);
$mainColor21: #202020;
$mainColor22: #808080;
$mainColor23: #FFC107; /* tooltip background color */
$mainColor24: darken($mainColor1, 8%);
$mainColor25: #3f4030; /* used as BG color of Admin blocks */
$mainColor26: #beede1; // link color

$projectionColor: #8603d8;
$projectionColorLight: #d092f7;
$projectionColorDark: #5c0a90;

$viewBGColor: $mainColor3;
$panelBGColor: $mainColor8;
$textColorLight:$mainColor5;
$text-color-active:$mainColor4;

$loadingImageBG: rgba(255,255,255,0.05);
$msgTypeTitle: $mainColor11;
$buttonBackgroundColor: $mainColor1;

$pollBarColor: $mainColor1;
$pollOptionColor: $mainColor11;
$pollOptionBackgroundColor: transparent;
$pollOptionBorderColor: $mainColor11;
$pollOptionSelectedColor: $mainColor3;
$pollOptionSelectedBackgroundColor: $mainColor1;
$pollOptionSelectedBorderColor: $mainColor1;
$pollOptionNewColor: $mainColor11;
$pollOptionNewBackgroundColor: transparent;
$pollOptionNewBorderColor: $mainColor11;
$pollOptionClickedColor: $mainColor4;
$pollOptionSelectedItemHoverBackgroundColor: $mainColor2;

$valueTitleColor: $mainColor1;

$likeColor: $mainColor4;
$dislikeColor: $mainColor9;
$messageCommentBubbleColor: #3c4743;
$messageCommentProjectedBubbleColor: $projectionColorDark;
$plainMsgBG: $mainColor2;

// inputs
$inputFieldBG: $mainColor12;
$inputFieldBGFocus: $mainColor7;

// dialog
$dialogBackground: $mainColor22;
