@import 'colors';
@import 'variables';

:root {
  --tooltip-background-color: #{$mainColor23};
  --tooltip-color: #{$mainColor0};
  --tooltip-font: #{$normalFont} 1.3rem #{$font-family-sans-serif};
  --tooltip-z-index: 1000000;
  --tooltip-shadow: 0 0 2px #{rgba($mainColor1, 0.5)};
}
@import 'tooltip'; // ~@myia/ngx-tooltip/src/lib/styles/ path defined scssIncludePaths (webpack - module.js)

.tooltip {
  line-height: 1.3;
  &[data-tooltip-placement^="right"], &[data-tooltip-placement^="left"] {
    text-align: left;
  }
}

