@import 'colors';
@import 'variables';
@import 'functions';
@import 'dialog-custom.scss';
@import "toasts";
@import 'dialog';
// import cropper css
@import '~cropperjs/dist/cropper.min';
// fonts
@import 'fonts.scss';
@import 'tooltip-custom.scss';
@import 'animations.scss';
@import 'emoticons.scss';
@import 'navBar.scss';
@import 'dropDown.scss';

:root {
  // circle progressIndicator
  --circle-indicator-color: #{$mainColor1};
  --bar-indicator-color: #{$mainColor1};
}

// bootstrap like styles
html {
  min-height: 100%;
  height: 100%;
  font-size: 10px; // base font size used by rem units
}

body {
  min-height: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  font-family: $font-family-sans-serif;
  font-size: 1.5rem;
  line-height: 1.42857;
  margin: 0;
}


.body {
  padding-top: $topBarHeight;
}

.hide {
  display: none;
}

.hidden {
  display: none !important;
}

.vertical-center {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.text-center {
  text-align: center;
}


h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-sans-serif;
  font-weight: $semiNormalFont;
  line-height: 1.1;
  color: inherit;
}

h1 {
  font-size: 39px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 19px;
}

h5 {
  font-size: 15px;
}

h6 {
  font-size: 13px;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

* {
  box-sizing: border-box;
}

*:before, *:after {
  box-sizing: border-box;
}

// fill width container
.container {
  margin: 0 auto;
  width: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: $mainColor26;

  &:focus {
    outline: 1px dashed $mainColor11;
    outline-offset: 3px;
  }

  &.focus--mouse {
    outline: none !important;
  }

}

button {
  outline: none !important;

  background: $mainColor1;
  border-radius: $buttonRadius;
  padding: 0 15px;
  border: none;
  color: $mainColor0;
  font-size: 12px;
  line-height: 2.5;

  position: relative;
  z-index: 1;
  cursor: pointer;

  &:not(.noDefStyles) {
    &:focus {
      &:before {
        content: ' ';
        position: absolute;
        z-index: -1;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        border: 2px solid $mainColor11;
        border-radius: 10px;
      }
    }

    &.focus--mouse {
      &:before {
        display: none !important;
      }
    }
  }

  span {
    vertical-align: middle;
  }

  &.withLargeIcon {
    position: relative;
    padding-left: 40px;

    .icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translate(0, -50%);
      font-size: 3rem;
    }
  }

  &.withMenu {
    border-radius: 8px 0 0 8px;

    &:focus:before {
      border-radius: 10px 0 0 10px;
    }
  }

  &.buttonMenu {
    border-radius: 0 8px 8px 0;
    margin-left: 1px;
    padding: 0 4px;

    .alignSpan {
      width: 0;
      display: inline-block;
    }

    span {
      vertical-align: middle;

      &.icon {
        margin: 0 2px 0 0 !important;
      }
    }

    &:focus:before {
      border-radius: 0 10px 10px 0;
    }
  }

  &.btnAdmin {
    background: $mainColor14;
    color: $mainColor0;

    &:hover {
      background: $mainColor16;
    }

    &:disabled {
      background: $mainColor14;
      color: $mainColor0;
      opacity: 0.5;
    }

    &:focus:before {
      border-color: darken($mainColor14, 40%);
    }

  }

  &.btnPlain {
    background: none;
    border-radius: 0;
    padding: inherit;
    color: $mainColor9;
    text-decoration: underline;
    line-height: 1.5;

    &:hover {
      background: transparent;
      color: $mainColor1;
    }

    &:disabled {
      background: transparent;
      color: $mainColor5;
    }

    &:focus:before {
      border-radius: 0;
      top: 0;
      left: -10px;
      right: -10px;
      bottom: 0;
      border: 1px dashed #5b6b6e;
    }

    &.btnAdmin {
      color: $mainColor14;

      &:hover {
        color: $mainColor16;
      }

      &:disabled {
        color: $mainColor14;
        opacity: 0.5;
      }
    }

  }

  .icon {
    text-decoration: none;
    margin-right: 8px;
    vertical-align: middle;
    position: static;
  }

  &:hover {
    background: $mainColor4;
  }

  &:disabled {
    background: $mainColor1;
    opacity: 0.4;
    cursor: default;
  }

  &.large {
    padding: 10px 20px;
    text-transform: uppercase;
    line-height: 1.7rem;
  }
}

.withClipboard {
  position: relative;

  &:after {
    content: '?';
    position: absolute;
    right: -5px;
    top: -5px;
    padding: 2px 5px;
    display: block;
    text-align: center;
    color: $mainColor0;
    background: $mainColor14;
    font-size: 1.2rem;
    font-weight: $boldFont;
    font-family: $font-family-sans-serif;
    border-radius: 20px;
    line-height: 1;
  }
}


input[type=text], input[type=password] {
  color: #fff;
  background: transparent;
  border: 2px solid transparent;
  outline: none;
  border-bottom: 1px solid $mainColor7;
  border-radius: 0;
}

/* IE10+ */
::-ms-clear {
  display: none;
}

/* Chrome */
::-webkit-search-decoration,
::-webkit-search-cancel-button,
::-webkit-search-results-button,
::-webkit-search-results-decoration {
  display: none;
}

.textFieldGroup {
  position: relative;
  margin-bottom: 20px;

  &.withLabel {
    margin-bottom: $inputLabelHeight;
  }

  &.init {
    label {
      transition: 0.2s ease all;
    }
  }

  input {
    position: relative;
    z-index: 3;
    padding: $defaultVertPadding 10px;
    width: 100%;
    border: 1px solid transparent;
    border-bottom: 1px solid $mainColor10;
    background: $inputFieldBG;

    &:disabled {
      color: $mainColor5;
    }

    &.password.hasValue {
      border-right: $passwordButtonWidth solid transparent;
    }
  }

  label {
    color: $mainColor22;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: $normalFont;
    position: absolute;
    z-index: 4;
    pointer-events: none;
    left: 0;
    top: 50%;
    margin-bottom: 0;
    transform: translate(0, -50%);
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    padding: 1px 7px 1px 10px;
    width: 100%;
    height: $inputLabelHeight;
    line-height: $inputLabelHeight;
  }

  .bar {
    position: absolute;
    bottom: -($inputLabelHeight);
    width: 100%;
    z-index: 1;
    height: $inputLabelHeight;
    background: $mainColor10;
    transform: scaleY(0);
    transform-origin: 0 0;
    transition: 0.2s ease transform;
  }

  .btn-reveal {
    padding: 5px 15px 5px 15px;
    color: #fff;
    background: transparent;
    outline: none;
    border-bottom: 1px solid $mainColor10;
  }

  input:read-only {
    color: $mainColor5;
  }

  /* active state */
  input:focus {
    background: $inputFieldBGFocus;

    &:read-only {
      border-bottom: 1px dashed $mainColor10;
    }
  }

  input:disabled {
    & ~ .reqMark {
      opacity: 0;
    }
  }

  input.hasValue, input.opened {
    border-bottom: 1px solid transparent;

    & ~ label {
      font-size: $inputLabelFontSize;
      color: $mainColor0;
      top: 100%;
      transform: translate(0, 0);
    }

    & ~ .bar {
      transform: scaleY(1.0);
    }

    & ~ .reqMark {
      opacity: 0;
    }

    & ~ .calendarWrapper {
      bottom: -($inputLabelHeight);
    }
  }

  input.opened {
    border-bottom: 1px solid $mainColor10;
  }

  .reqMark {
    pointer-events: none;
    position: absolute;
    z-index: 5;

    &.line {
      height: 0;
      transition: 0.3s ease width;
      transform-origin: 0 0;
    }
  }

  &.required {
    .reqMark {
      &.line {
        left: 0;
        bottom: -1px;
        width: 30px;
        max-width: 30%;
        height: 2px;
        background: linear-gradient(to right, $mainColor1 0%, $mainColor1 30%, rgba($mainColor1, 0) 100%);
      }
    }
  }

  &.modified {
    &:after {
      position: absolute;
      z-index: 1;
      content: '';
      right: 1px;
      top: 1px;
      width: 0;
      height: 0;
      border-right: 3px solid $mainColor1;
      border-top: 3px solid $mainColor1;
      border-bottom: 3px solid transparent;
      border-left: 3px solid transparent;
    }
  }
}

$formPaddingH: 40px;
$formPaddingV: 30px;
$formBorderWidth: 1px;

.formBlock {
  border: $formBorderWidth solid $mainColor12;
  padding: $formPaddingV $formPaddingH;
  background: $mainColor8;

  &.noFrame {
    border: none;
    background: none;
    padding: 10px 0;
  }

  form {
    transition: all 0.5s ease;
    background-color: rgba(0, 0, 0, 0);

    &.validating {
      background-color: rgba(1, 1, 1, 0); /* Change background color to trigger css animation (required by AnimationBuilder) */
      input.ng-invalid {
        & ~ .reqMark {
          &.line {
            width: 60px !important;
          }

          &.circle {
            opacity: 1;
            transform: scale(1.2);
          }
        }

        & ~ label {
          color: $mainColor5;
        }
         & ~ * {
           .reqMark {
             &.fade {
               opacity: 1;
             }
           }
         }
      }

      .formErrorRow {
        transform: translate(5px, 0);
      }

      .dropdown {
        .dropdown.invalid {
          &:before {
            opacity: 0.6;
          }
          //border: 1px dashed $mainColor1;
        }
      }
    }
  }
}

.formRow {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .formRowIcon {
    align-self: flex-start;
    font-size: 2.2rem;
    line-height: 1.4;
    margin: 10px 20px 0 0;
    color: $mainColor11;
    display: none;
    transition: color 1s $defaultAnimationEasing;

    &.active {
      color: $mainColor4;
    }

    @include sm {
      display: block;
    }
  }

  .inputField {
    flex: 1 0 auto;
    margin-right: 0;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  @include sm {
    flex-direction: row;
    align-items: flex-start;
    .inputField {
      margin-right: 20px;
    }
    &.center {
      align-items: center;
    }
  }
}

.formErrorRow {
  transition: transform 0.3s ease;
  transform-origin: 0 0;
  margin: 0 0 20px 0;
  color: $mainColor14;
  position: relative;

  .icon {
    position: absolute;
    left: -28px;
    font-size: 2rem;
  }
}

.formTitle {
  color: $mainColor1;
  font-size: 2.8rem;
  font-weight: $lightFont;
  margin: 0 0 40px 0;
}

.formButton {
  margin-top: 20px;
  text-transform: uppercase;

  &.onlyText {
    background: transparent;
    color: $mainColor1;

    &:hover {
      background: transparent;
      color: $mainColor9;
      text-decoration: underline;
    }
  }
}

.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

label {
  color: $mainColor5;
}

.links {
  margin: 20px 0;

  .btn {
    margin: 2px 0;
  }
}

.router-link-active, .router-link-active:active, .router-link-active:focus {
  color: $text-color-active;
}

.viewContentRoot {
  background: $viewBGColor;
  min-height: 100%;
  position: relative;

  & > .loadingModule {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    font-weight: $lightFont;
    color: $mainColor22;
  }

  @include sm {
    overflow-y: auto;
    // enable momentum scrolling
    &.ios {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }
}

.viewContent {
  border-radius: 0;
  padding: 0;
  min-height: initial;
  height: 100%;
  &.loading {
    opacity: 0;
  }
}

.subPageTitleBlock {
  padding: 1px 0;
  margin-bottom: 5px;
  position: relative;

  &:nth-child(odd) {
    //background: #202020;
  }

  .navBackButton {
    color: $mainColor6;
    cursor: pointer;
    margin: 20px 20px -10px 20px;
    text-transform: uppercase;
    position: relative;

    span {
      text-decoration: underline;
    }

    &:before {
      content: '<';
      position: relative;
      margin-right: 10px;
    }
  }
}

.subPageTitle {
  font-family: $portal-font-family-narrow;
  font-size: 2.4rem;
  color: $mainColor9;
  font-weight: $narrowLightFont;
  margin: 10px 20px 0 20px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  &.autoFontSize {
    text-overflow: 'clip';
  }
  @include sm {
    font-size: 3.6rem;
    margin: 20px 20px 0 20px;
    white-space: nowrap;
  }

  .autoFontSize {
    &.min {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    white-space: nowrap;
  }
}

.subPageDescription {
  font-weight: $lightFont;
  margin: 0 20px;
  padding: 10px 0;
  border-top: 1px solid $mainColor2;
  color: $mainColor6;
}

.subPageSection {
  background: $mainColor2;
  padding: 20px;
}

.subPageContent {
  margin: 20px 20px;
}

.subPageActionsBar {
  margin: 10px 20px 20px 20px;

  button {
    text-transform: uppercase;
    vertical-align: middle;

    .icon {
      margin-right: 8px;
    }

  }
}

.content-with-sidebar {
  overflow-y: auto;
  // enable momentum scrolling
  &.ios {
    overflow-y: visible;
  }

  @include sm {
    overflow-y: visible;
    display: flex;
    flex-direction: row;
    &.ios {
      overflow-y: visible;
    }
  }
}

.dynWidthContent {
  transition: width 0.5s $defaultAnimationEasing;
  flex: 1;
}

.animatedBlock {
  transition: opacity 0.5s linear;
}

.busyBlock {
  opacity: 0.3;
  pointer-events: none;
}

@include sm {

  .viewContent {
    min-height: initial;
    border-radius: 6px 6px 0 0;
    padding: 0; //must be at least 1px otherwise layout is corrupted in chrome
    margin: 0;
  }

}

.noPadding {
  padding: 0 !important;
}

.noMargin {
  margin: 0 !important;
}

.fullHeight {
  height: 100%;
}

.clearfix {
  @include clearFix();
}

.floatRight {
  float: right;
}

.star {
  flex: 1;
}


.myia-logo {
  display: block;
  width: 100%;
  height: 100%;
  background: url('../img/myiaLogo.svg') center center no-repeat;
  background-size: contain;

}

// custom toast module styles
.toastModule.iziToast.toastMessage > .iziToast-body > p {
  font-size: 1.4rem;
}

// croppingService styles
.croppedImageContainer {
  position: absolute;
  z-index: 90; /* lower than 100: It's mce editor toolbars/menus */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  &:before {
    content: '';
    position: fixed;
    z-index: 80; /* lower than 100: It's mce editor toolbars/menus */
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
  }


  .croppedImageContainerInner {
    position: absolute;
    z-index: 90; /* lower than 100: It's mce editor toolbars/menus */
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      left: -1px;
      top: -1px;
      right: -1px;
      bottom: -1px;
      border: 1px dotted $mainColor1;
    }

    .cropper-container {
      z-index: 10;
    }

    $cropperButtonsSize: 32px;

    .cropperButtons {
      position: absolute;
      z-index: 1;
      left: 50%;
      white-space: nowrap;
      transform: translate(-50%, 0);
      bottom: -$cropperButtonsSize;

      button {
        border-radius: 0;
        width: $cropperButtonsSize;
        height: $cropperButtonsSize;
        padding: 2px;

        .icon {
          display: inline-block;
          margin: 0;
          line-height: 1;
          font-size: 1.8rem;

          svg {
            width: 1em;
            height: 1em;
          }
        }

        &:focus {
          outline-offset: -5px;
          outline: 1px dashed $mainColor0 !important;

          &:before {
            display: none;
          }
        }

        &.focus--mouse {
          outline: none !important;
        }
      }

      .delim {
        width: 5px;
        display: inline-block;
      }
    }
  }
}

.pageHelpTopSection {
  text-align: right;
  position: relative;
  margin-bottom: 40px;
}

.helpIcon.pageHelp {
  position: static;
  margin-right: 10px !important;
  margin-top: 10px !important;;
}
