@import 'colors';

.emoticon {
  text-indent: -9999px;
  color: transparent;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: 1px 2px 2px 2px;
  vertical-align: middle;

  &.angry {
    background: url('../img/emoticons/Angry.svg') no-repeat;
    background-size: contain;
  }
  &.cool {
    background: url('../img/emoticons/Cool.svg') no-repeat;
    background-size: contain;
  }
  &.cry {
    background: url('../img/emoticons/Cry.svg') no-repeat;
    background-size: contain;
  }
  &.love {
    background: url('../img/emoticons/Love.svg') no-repeat;
    background-size: contain;
  }
  &.sad {
    background: url('../img/emoticons/Sad.svg') no-repeat;
    background-size: contain;
  }
  &.smile {
    background: url('../img/emoticons/Smile.svg') no-repeat;
    background-size: contain;
  }
  &.smiley {
    background: url('../img/emoticons/Smiley.svg') no-repeat;
    background-size: contain;
  }
  &.surprised {
    background: url('../img/emoticons/Surprised.svg') no-repeat;
    background-size: contain;
  }
  &.tongueOut {
    background: url('../img/emoticons/TongueOut.svg') no-repeat;
    background-size: contain;
  }
  &.wink {
    background: url('../img/emoticons/Wink.svg') no-repeat;
    background-size: contain;
  }
}
