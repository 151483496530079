@import 'colors';
@import 'functions';
@import 'variables';

.nsm-dialog.nsm-dialog-open {

  max-width: none;

  &.w450 {
    .nsm-content {
      width: 450px;
      max-width: 90vw;
    }
  }

  &.w640 {
    .nsm-content {
      width: 640px;
      max-width: 90vw;
    }
  }
  &.w800 {
    .nsm-content {
      width: 800px;
      max-width: 90vw;
    }
  }
  &.w1000 {
    .nsm-content {
      width: 1000px;
      max-width: 90vw;
    }

  }
  &.form {
    .nsm-content {
      background: $mainColor12;
    }
    .dialogTitle {
      background: $mainColor7;
    }
  }

}

.nsm-content {
  background: $dialogBackground !important;

  max-width: calc(100% - #{2*$dialogPadding});
  max-height: calc(100% - #{2*$dialogPadding}); //IE11
  border-radius: $dialogRadius !important;
  padding: $dialogPadding !important;

  .dialogMessage {
    padding: 20px;
    margin-bottom: 8px;
  }

  .dialogTitle {
    padding: 10px;
    margin: (-$dialogPadding) (-$dialogPadding) $dialogPadding (-$dialogPadding);
    border-radius: $dialogRadius $dialogRadius 0 0;
    font-family: $portal-font-family-narrow;
    font-size: 2rem;
    background: $mainColor10;
    position: relative;
    @include sm {
      padding: $dialogTitlePadding;
    }
  }

  .dialogMessage {
    padding: 20px;
    margin-bottom: .5em;
    font-size: 1.1em;
    line-height: 1.5em;
  }

  .dialogSubTitle {
    padding: 10px 0;
    font-size: 1.3rem;
  }

  .dialogButton {
    margin: 2px 8px 2px 0;
    line-height: 1;
    font-size: 1.2rem;
    padding: 0.8rem 1.5rem;

    border-radius: 3px;
    border: 0;
    font-family: inherit;
    text-transform: uppercase;

    text-rendering: optimizeLegibility;
    appearance: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &:last-child {
      margin-right: 0;
    }

    &:disabled {
      cursor: default;
    }

    @include sm {
      font-size: 1.4rem;
      padding: 1rem 2rem;
      min-width: 100px;
    }

    &.dialogButtonPrimary {
      background: $mainColor1;
      color: $mainColor0;
      font-weight: $boldFont;
      &:hover {
        background: $mainColor4;
      }
      &:disabled {
        background: $mainColor1;
      }
    }
    &.dialogButtonSecondary {
      background: #e0e0e0;
      &:hover {
        background: $mainColor9;
      }
      &:disabled {
        background: #e0e0e0;
      }
    }


    &:focus:before {
      border-radius: 6px;
      border-color: $mainColor6;
    }
  }

  &.withContent {
    .dialogMessage {
      padding: 10px;
    }
  }

  .dialogButtons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* clear fix */
    @include clearFix();

    & > * {
      margin-top: 10px;
    }

    @include sm {
      & > * {
        margin-top: $dialogTitlePadding;
      }
    }

    .spacer {
      flex: 1;
      min-width: 20px;
    }
  }

  .dialogButtonsSection {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    &.actionButtons {
      button {
        font-size: 1.2rem;
      }
    }
  }

}


.dialogContent {
  flex: 1;
  overflow-y: auto;
}

